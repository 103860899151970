const defaultTitles = {
  title: 'Schedule a visit',
  subTitle: 'It will only take a minute'
}
const callbackTitles = {
  title: 'Contact us',
  subTitle: 'In case of any questions or doubts reach out to us'
}
const confirmationTitles = {
  title: 'Your appointment is booked',
  subTitle: 'We sent the appointment details to you via text message and email'
}

export default {
  eReferrals: { path: '/e-referrals', title: defaultTitles.title, subTitle: defaultTitles.subTitle },
  eReferralsStart: { path: '/e-referrals/start', title: defaultTitles.title, subTitle: defaultTitles.subTitle },
  eReferralsSearch: { path: '/e-referrals/search', title: defaultTitles.title, subTitle: defaultTitles.subTitle },
  procedures: { path: '/procedures', title: defaultTitles.title, subTitle: defaultTitles.subTitle },
  insurers: { path: '/insurers', title: defaultTitles.title, subTitle: defaultTitles.subTitle },
  clinics: { path: '/clinics', title: defaultTitles.title, subTitle: defaultTitles.subTitle },
  patientForm: { path: '/patient-form', title: defaultTitles.title, subTitle: defaultTitles.subTitle },
  callback: { path: '/callback', title: callbackTitles.title, subTitle: callbackTitles.subTitle },
  confirmation: { path: '/confirmation', title: confirmationTitles.title, subTitle: confirmationTitles.subTitle }
};
