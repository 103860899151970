import config from '@/config'

/* eslint-disable */
const translations = {
  "pl": {
    "Home": "Dom",
    "Admin": "Administracja",
    "Areas": "Obszary",
    "Area": "Obszar",
    "Positions": "Pozycje",
    "Position": "Pozycja",
    "Connections": "Podłaczenia",
    "Connection": "Podłączenie",
    "Locations": "Lokalizacje",
    "Location": "Lokalizacja",
    "Devices": "Urządzenia",
    "Device": "Urządzenie",
    "Rack Devices": "Urządzenia sterownicze",
    "Rack Device": "Urządzenie sterownicze",
    "Connection Types": "Rodzaje podłączeń",
    "Connection Type": "Rodzaj podłączenia",
    "Settings": "Ustawienia",
    "Language": "Język",
    "Name": "Nazwa",
    "Description": "Opis",
    "Side": "Strona",
    "Delete": "Usuń",
    "Level": "Poziom",
    "Type": "Typ",
    "Are you sure you want to permanently remove": "Czy jesteś pewien, że chcesz trwale usunąć",
    "Yes": "Tak",
    "yes": "tak",
    "No": "Nie",
    "no": "nie",
    "404 Not Found": "404 Nie znaleziono",
    "Show missing translations": "Pokaż brakujące tłumaczenia",
    "Loading data...": "Ładowanie danych...",
    "area": "obszar",
    "404 Route not found": "404 Nie znaleziono adresu",
    "I have an e-referral": "Posiadam e-skierowanie",
    "I have a paper referral":"Posiadam papierowe skierowanie",
    "I don't have a referral":"Nie posiadam skierowania",
    "Please prepare your paper referral to provide some details written on it":"Prosimy o przygotowanie skierowania. Szczegóły w nim zawarte mogą być potrzebne do umówienia badania.",
    "MR and USG do not rquire a referral": "Badania Rezonansu Magnetycznego oraz USG nie wymagają skierowania.",
    "Please note that there is a limited number of procedures available without a referral":"Badania Tomografii Komputerowej, RTG, PET wymagają skierowania od lekarza specjalisty.",
    "I agree to process my data in agreement with Affidea":"Zapoznałem się z ",
    "I agree to be contacted by Affidea":"Chcę zadać pytanie – proszę o kontakt telefoniczny",
    "I agree to receive marketing information from Affidea.":"Chcę otrzymywać od Affidea drogą telefoniczną informacje handlowe i marketingowe dotyczących świadczonych przez nią usług, w tym oferty spersonalizowane.",
    "What it means?":"Co to oznacza?",
    "Choose":"Wybierz",
    "Provide the e-referral details":"Wprowadź dane e-skierowania",
    "Where can I find the E-referral PIN?":"Gdzie mogę znaleźć PIN do e-skierowania?",
    "Schedule a visit":"Umów badanie",
    "Check availability":"Sprawdź inne terminy",
    "It will only take a minute":"To zajmie tylko chwilę",
    "Select procedure":"Wybierz rodzaj badania",
    "Clear":"Wyczyść",
    "Our line is available:":"&nbsp;",//"Infolinia czynna:",
    "E-referral PIN":"Kod dostępu e-skierowania",
    "Book appointment":"Umów badanie",
    "Privacy Policy":"Informacją o ochronie danych osobowych dla Pacjentów",
    "I accept":"Akceptacja",
    "terms and conditions":"Regulaminu",
    "Leave us a message":"Prośba o kontakt",
    "Not sure?":"Masz pytanie?",
    "Contact us":"Prośba o kontakt",
    "We will call you back":"Oddzwonimy do Ciebie",
    "Select insurer":"Wybierz płatnika",
    "Insurer additional info":"W przypadku realizacji badania refundowanego przez Ubezpieczycieli zapraszamy do kontaktu z Infolinią 224 411 111.",
    "Select clinic":"Wybierz centrum medyczne",
    "Selected insurer":"Wybrany płatnik",
    "Fetching availability...":"Wyszukuję dostępne terminy",
    "Please select a date to view available appointment times.":"Wybierz datę i godzinę badania.",
    "slots":"terminów",
    "slot":"termin",
    "Available slots":"Dostępne terminy",
    "Selected clinic":"Wybrane centrum",
    "Retrieve e-referral":"Wyszukaj e-skierowanie",
    "and schedule a visit for you":"i umówimy badanie dla Ciebie",
    "Monday - Saturday: 8:00 - 16:00":"Poniedziałek - Piątek: 8:00 - 20:00",
    "BACK":"Powrót",
    "Please enter callback details 1":"Wprowadź dane kontaktowe, a oddzwonimy do Ciebie.",
    "Please enter callback details 2":"Możesz również skontaktować się z Nami bezpośrednio pod numerem 22 44 11 111.",
    "Please enter callback details 3":" ",
    "Select preferred callback time":"Wybierz preferowany czas kontaktu.",
    "Please enter correct PESEL.":"Wprowadź poprawny numer PESEL.",
    "First name":"Imię",
    "Please enter correct first name.":"Pole Imię jest wymagane.",
    "Last name":"Nazwisko",
    "Please enter correct last name.":"Pole Nazwisko jest wymagane.",
    "Mobile number": "Telefon kontaktowy",
    "Please enter correct mobile number.":"Wprowadź poprawny telefon kontaktowy.",
    "Save callback": "Wyślij prośbę",
    "Please accept terms and conditions.":"Akceptacja regulaminu jest obowiązkowa.",
    "Please accept to be contacted.":"Zaznaczenie zgody na kontakt telefoniczny jest obowiązkowe.",
    "Procedure": "Badanie",
    "Insurer": "Płatnik",
    "day": "dzień",
    "days": "dni",
    "Scheduling appointment...":"Rezerwuję badanie ...",
    "Request callback has been successfully sent.":"Twoja prośba o kontakt telefoniczny została pomyślnie wysłana.",
    "To pay in clinic":"Cena badania",
    "Your final price will be provided once the exact required procedure is confirmed during the visit":"Ostateczna cena zostanie ustalona po zweryfikowaniu badania w Centrum Affidea.",
    "Selected procedure":"Wybrany rodzaj badania",
    "Clinic": "Centrum",
    "Contact details": "Dane kontaktowe",
    "Clear selection": "Cofnij wybór",
    "SMS code is required":"Kod SMS jest wymagany",
    "SMS code must be 6 digits long":"Kod SMS powinien mieć 6 cyfr",
    "Incorrect SMS code, try again":"Niepoprawny kod SMS, spróbuj ponownie",
    "Success":"Sukces",
    "Time slot booked successfully.":"Termin badania został zarezerwowany.",
    "E-referral no.":"Numer e-skierowania",
    "ICD9 codes":"Kod badania",
    "ICD10 codes":"Rozpoznanie",
    "Issued by":"Wystawione przez",
    "Issued on":"Data wystawienia",
    "Patient name and surname":"Imię i nazwisko pacjenta",
    "Birth day":"Data urodzenia",
    "Home address":"Adres pacjenta",
    "No availability": "Brak dostępnych terminów",
    "Select different procedure":"Zmień rodzaj badania",
    "Select region":"Wybierz województwo",
    "Select different region":"Zmień województwo",
    "Show on map":"Pokaż na mapie",
    "Address":"Adres",
    "Earliest appointment":"Pierwszy wolny termin",
    "Select appointment date":"Wybierz termin badania",
    "Confirm":"Potwierdź",
    "Cancel":"Anuluj",
    "Prev":"Poprzedni",
    "week":"Tyd.",
    "Select day":"Wybierz dzień",
    "Week":"Tydzień",
    "Day":"Dzień",
    "Mon":"Pn",
    "Tue":"Wt",
    "Wed":"śr",
    "Thu":"Czw",
    "Fri":"Pt",
    "Sat":"Sb",
    "Sun":"Nd",
    "Monday":"Poniedziałek",
    "Tuesday":"Wtorek",
    "Wednesday":"Środa",
    "Thursday":"Czwartek",
    "Friday":"Piątek",
    "Saturday":"Sobota",
    "Sunday":"Niedziela",
    "January":"Styczeń",
    "February":"Luty",
    "March":"Marzec",
    "April":"Kwiecień",
    "May":"Maj",
    "June":"Czerwiec",
    "July":"Lipiec",
    "August":"Sierpień",
    "September":"Wrzesień",
    "October":"Październik",
    "November":"Listopad",
    "December":"Grudzień",
    "Jan":"St",
    "Feb":"Lut",
    "Mar":"Mrz",
    "Apr":"Kw",
    "Jun":"Cz",
    "Jul":"Lip",
    "Aug":"Sier",
    "Sep":"Wrz",
    "Oct":"Paź",
    "Nov":"Lis",
    "Dec":"Gr",
    "Select time slot":"Wybierz godzinę",
    "Please enter correct PIN.":"Wprowadź poprawny kod dostępu e-skierowania.",
    "No time slots for this day are available":"Brak wolnych terminów w wybranym dniu.",
    "Fill in your contact data":"Wprowadź dane",
    "Personal details":"Dane osobowe",
    "Book Appointment":"Zarezerwuj badanie",
    "Birthday date":"Data urodzenia",
    "Phone number must be valid":"Wprowadź poprawny numer telefonu",
    "E-mail must be valid":"Wprowadź poprawny adres email",
    "Zip code":"Kod pocztowy",
    "Town":"Miasto",
    "Not all mandatory fields have been completed":"Pola oznaczone na kolor czerwony są obowiązkowe",
    "Street name":"Ulica",
    "Street number":"Numer ulicy",
    "Apt number":"Numer mieszkania",
    "Phone number":"Telefon kontaktowy",
    "Email address":"Adres email",
    "Email address (optional)":"Adres email (opcjonalnie)",
    "Confirm your phone number":"Potwierdź telefon kontaktowy",
    "We sent a confirmation code via text message to":"Na poniższy numer telefonu wysłaliśmy jednorazowy kod autoryzacji:",
    "Please enter the code in the field below to confirm your phone number":"Wprowadź otrzymany kod w polu poniżej.",
    "SMS Code":"Kod SMS",
    "Your appointment is booked":"Dziękujemy. Twoje badanie zostało zarezerwowane.",
    "We sent the appointment details to you via text message and email":"Poniżej znajdują się szczególy Twojego badania.",
    "Visit date":"Data badania",
    "How to prepare for the procedure?":"Jak przygotować się na badanie?",
    "Learn more":"Jak przygotować się do badania",
    "Go to Homepage":"Wróć do strony głównej",
    "Confirmation text 1":"W celu zapewnienia jak najwyższej jakości naszych usług zweryfikujemy przesłaną do nas rezerwację.",
    "Confirmation text 2":"Dziękujemy za wybór Affidea.",
    "Confirmation text list option 1":"jeśli umówienie badania będzie wymagało dodatkowych informacji skontaktuje się z Panią/Panem nasz Konsultant.",
    "Confirmation text list option 2":"w przypadku braku konieczności uzupełnienia danych, na wskazany nr telefonu zostanie przesłany sms z potwierdzeniem badania.",
    "In case of any questions or doubts reach out to us": "W razie jakichkolwiek pytań, skontaktuj się z nami",
    "e-Referral not found header":"Niestety, nie udało nam się pobrać Twojego e-skierowania na podstawie wprowadzonych danych.",
    "e-Referral not found part 1.":"Prosimy o ich weryfikację i ponowną próbę.",
    "e-Referral not found part 2.":"Jeśli masz jakiekolwiek wątpliwości zadzwoń do nas pod numer tel. 224 411 111 lub kliknij w Prośba o kontakt poniżej, a oddzwonimy o wybranej przez Ciebie godzinie.",
    "in": "za",
    "Close": "Zamknij",
    "must be less than or equal to": "może zawierać maksymalnie",
    "characters": "znaków",
    "is required": "jest wymagane",
    "is required_f": "jest wymagana",
    "Where can I find the missing data?": "Gdzie mogę odnaleźć brakujące dane?",
    "The procedure wasn't specified at e-referral. Please provide with the procedure details": "W e-skierowaniu nie zostało określone badanie. Proszę o podanie szczegółów badania.",
    "Patient number is invalid": "Podany numer PESEL jest nieprawidłowy",
    "Agreement must be accepted in order to continue": "Akceptacja jest niezbędna aby kontynuować",
    "Complete this step before the timer expires, otherwise this booking will be lost": "Potwierdź kod SMS przed upływem czasu, inaczej rezerwacja zostanie anulowana",
    "I agree for the marketing communication by Affidea": "Wyrażam zgodę na kontakt marketingowy za pośrednictwem",
    "agreement email": "poczty elektronicznej",
    "agreement sms/mms": "wiadomości SMS/MMS",
    "agreement phone": "telefonu (rozmowa telefoniczna, komunikator głosowy IVR)",
    "Today": "Dzisiaj",
    "E referral only for scheduling best slot.": "Dane z Twojego e-skierowania ułatwią znalezienie terminu odpowiedniego do specyfiki zleconego badania.",
    "E referral will not be submitted.": "Jeśli nie zarezerwujesz terminu badania w ostatnim kroku, to Twoje e-skierowanie nie zostanie wykorzystane.",
    "contact phone number": "+ 22 44 11 111",
    "contact hours": "&nbsp;",//"Pn - Pt&nbsp;&nbsp;8:00 - 19:00<br/>Sb&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;8:00 - 16:00",
    "Could not retrieve e-referral. Please try again.": "Nie można pobrać e-skierowania. Spróbuj jeszcze raz.",
    "Could not book appointment. Please try again.": "Nie można zarezerwować wizyty. Spróbuj jeszcze raz.",
    "Price from": "Cena od",
    "currency symbol": "zł",
    "Price can change based on ...": "Cena może ulec zmianie w zależności od potencjalnych zaleceń lekarza radiologa np. podanie kontrastu lub zmiana procedury.",
    "Information not the offer with respect to ...": "Powyższa informacja nie stanowi oferty w rozumieniu art. 66 § 1 Kodeksu cywilnego.",
    "Administrator personal data 1": "Administratorem Twoich danych osobowych przetwarzanych w celu umówienia wizyty jest jest Affidea Sp. z o.o., Plac Europejski 2, 00-844 Warszawa. Więcej informacji na temat sposobu przetwarzania Twoich danych osobowych przez Affidea znajdziesz w",
    "administrator personal data 2": "informacjach o ochronie danych osobowych",
    "Agreement to process personal data 1": "Zaznaczając niniejsze pole i naciskając przycisk „Wyślij prośbę” wyrażasz dobrowolną zgodę na przetwarzanie Twoich danych osobowych przez Affidea* w celu otrzymywania informacji o najnowszej ofercie Affidea* w zakresie usług medycznych za pośrednictwem środków komunikacji, na które wyrazisz zgodę poprzez zaznaczenie określonych pól (tj. poczta elektroniczna / wiadomości SMS/MMS / kontakt telefoniczny).",
    "agreement to process personal data 2": "(*) Affidea Sp. z o.o., Plac Europejski 2, 00-844 Warszawa",
    "Could not find any slots within next": "Nie udało się znaleźć dostępnych terminów przez kolejne",
    "weeks": "tygodni",
  },
  "en" : {
    "is required_f": "is required",
    "Confirmation text 1": "Thank you for completing the booking request.",
    "Confirmation text list option 1": "You should receive your confirmation shortly.",
    "Confirmation text list option 2": "  ",
    "Confirmation text 2": "  ",
    "Administrator personal data 1": "Further information on the data protection is available under:",
    "administrator personal data 2": "Data Protection"

  }
}
window.missingTranslations = {
  pl: {}
}
const hideTranslated = false
const translate = {
  install (Vue) {
    let additionalTranslations = {}

    if (config.translations) {
      try {
        additionalTranslations = JSON.parse(config.translations)
      } catch (e) {
        additionalTranslations = {}
      }
    }

    if (Object.keys(additionalTranslations).length) {
      for (const langIndex in additionalTranslations) {
        if (typeof translations[langIndex] !== 'undefined') {
          translations[langIndex] = {...translations[langIndex], ...additionalTranslations[langIndex]}
        } else {
          translations[langIndex] = additionalTranslations[langIndex]
        }
      }
    }

    Vue.prototype.$_t = function (msg) {
      // use this to turn on translations
      // return this.$t(msg)
      if (hideTranslated) {
        return '-'
      }
      // use this to turn off translations
      const lang = localStorage.getItem('lang') || 'en'
      if (lang !== 'en' && (typeof translations[lang] === 'undefined' || !translations[lang][msg])) {
        window.missingTranslations[lang] = window.missingTranslations[lang] || {}
        window.missingTranslations[lang][msg] = ''
      }


      return typeof translations[lang] !== 'undefined' && translations[lang][msg] || msg

      // use this to only show untranslated text
      // console.log(msg)
      // return '-'
    }
  }
}

export default translate
