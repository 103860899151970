export default {
  alternativeConfirmationDetails: (process.env.VUE_APP_ALTERNATIVE_CONFIRMATION_DETAILS) ?? '',
  displayReferralStep: (process.env.VUE_APP_DISPLAY_REFERRAL_STEP === 'true'),
  contactPhoneNumber: (process.env.VUE_APP_CONTACT_PHONE_NUMBER) ?? '',
  policyLink: (process.env.VUE_APP_POLICY_LINK) ?? 'https://google.com',
  cashPayerInsurerId: (process.env.VUE_APP_CASH_PAYER_INSURER_ID) ?? null,
  recaptchaKey: '6LfQNT4aAAAAAJnjPd3p-_ZcYYPtEbs0j23ThTbu',
  languageSwitchEnabled: (process.env.VUE_APP_LANGUAGE_SWITCH_ENABLED && process.env.VUE_APP_LANGUAGE_SWITCH_ENABLED === 'true'),
  patientFormComponentName: (process.env.VUE_APP_PATIENT_FORM_COMPONENT_NAME) ?? '',
  patientFormSettingsJson: (process.env.VUE_APP_PATIENT_FORM_SETTINGS_JSON) ?? '{}',
  currency: (process.env.VUE_APP_CURRENCY) ?? 'zł',
  currencyPrefix: (process.env.VUE_APP_CURRENCY_PREFIX) ?? '£',
  currencySuffix: (process.env.VUE_APP_CURRENCY_SUFFIX) ?? '',
  homepageLink: (process.env.VUE_APP_HOMEPAGE_LINK) ?? '',
  startPage: (process.env.VUE_APP_START_PAGE) ?? '/e-referrals/start',
  contactHours: (process.env.VUE_APP_CONTACT_HOURS) ?? 'Pn - Pt&nbsp;&nbsp;7:00 - 20:00<br/>Sb&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;8:00 - 20:00',
  scanLearnMoreLinks: {
    MRI: (process.env.VUE_APP_LEARN_MORE_LINK_MRI) ?? '',
    CT: (process.env.VUE_APP_LEARN_MORE_LINK_CT) ?? ''
  },
  dateFormat: (process.env.VUE_APP_DATE_FORMAT) ?? 'DD/MM/YYYY',
  defaultLanguage: (process.env.VUE_APP_DEFAULT_LOCALE) ?? 'en',
  confirmationCountdownInSeconds: (process.env.VUE_APP_CONFIRMATION_COUNTDOWN_IN_SECONDS) ?? '600',
  logoImg: (process.env.VUE_APP_LOGO_IMG) ?? '',
  googleGTM: (process.env.VUE_APP_GOOGLE_GTML) ?? '',
  googleGTMConfirmation: (process.env.VUE_APP_GOOGLE_GTML_CONFIRMATION) ?? '',
  zohoChatWidgetCode: (process.env.VUE_APP_ZOHO_CHAT_WIDGET_CODE) ?? '',
  customerName: (process.env.VUE_APP_CUSTOMER_NAME) ?? '',
  themeLightPrimary: (process.env.VUE_APP_THEME_LIGHT_PRIMARY) ?? '#418fde',
  themeBackgroundGradientStart: (process.env.VUE_APP_THEME_BACKGROUND_GRADIENT_START) ?? '#31d4ff',
  themeBackgroundGradientEnd: (process.env.VUE_APP_THEME_BACKGROUND_GRADIENT_END) ?? '#3295ff',
  themeBackgroundOpacity: (process.env.VUE_APP_THEME_BACKGROUND_OPACITY) ?? '0.81',
  scheduleAlternativeMobileView: (process.env.VUE_APP_SCHEDULE_ALTERNATIVE_MOBILE_VIEW && process.env.VUE_APP_SCHEDULE_ALTERNATIVE_MOBILE_VIEW === 'true'),
  urlDataProtectionPolicy: (process.env.VUE_APP_URL_DATA_PROTECTION_POLICY) ?? '',
  urlTermsAndConditions: (process.env.VUE_APP_URL_TERMS_AND_CONDITIONS) ?? '',
  urlPrivacyNotice : (process.env.VUE_APP_URL_PRIVACY_NOTICE) ?? '',
  useProcedures: (process.env.VUE_APP_USE_PROCEDURES === 'true'),
  skipSmsAuthentication: (process.env.VUE_APP_SKIP_SMS_AUTHENTICATION === 'true'),
  showExtendedConfirmation: (process.env.VUE_APP_SHOW_EXTENDED_CONFIRMATION === 'true'),
  labelNoAvailability: (process.env.VUE_APP_LABEL_NO_AVAILABILITY) ?? '',
  translations: (process.env.VUE_APP_TRANSLATIONS) ?? '',
  dataStorageConsentEnabled : process.env.VUE_APP_DATA_STORAGE_CONSENT_ENABLE == 'true'
}

