<template>
  <div id="app">
    <v-app>
      <router-view :key="$route.name"/>
      <notifications group="booking" />
    </v-app>
    <!-- Google Tag Manager (noscript) -->
    <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KK8V8S2"
                      height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
    <!-- End Google Tag Manager (noscript) -->
  </div>
</template>

<script>
  import config from '@/config'

  export default {
    setup() {
      const initGoogle = () => {
          (function (w, d, s, l, i) {
          w[l] = w[l] || [];
          w[l].push({'gtm.start': new Date().getTime(), event: 'gtm.js'});
          var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != 'dataLayer' ? '&l=' + l : '';
          j.async = true;
          j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
          f.parentNode.insertBefore(j, f);
        })(window, document, 'script', 'dataLayer', config.googleGTM);
      }

      if (config.googleGTM) {
        initGoogle()
      }

      const initZohoChat = () => {
        window.$zoho = window.$zoho || {}
        window.$zoho.salesiq = window.$zoho.salesiq || {
          widgetcode: config.zohoChatWidgetCode,
          values: {},
          ready:function(){}
        }
        const d = document
        const s = d.createElement('script')
        s.type = 'text/javascript'
        s.id = 'zsiqscript'
        s.defer = true
        s.src = 'https://salesiq.zoho.eu/widget'
        const t = d.getElementsByTagName('script')[0]
        t.parentNode.insertBefore(s, t);
      }

      if (config.zohoChatWidgetCode) {
        initZohoChat()
      }
    }
  }
</script>
