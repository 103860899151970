<template>
  <div>
    <div v-if="loading" class="text-center pt-12">
      <v-progress-circular
        :size="75"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
    <div v-else>
      <span class="title grey--text text--darken-1">{{
        $_t('Select insurer')
      }}</span>
      <div
        v-if="!insurers.length && !config.cashPayerInsurerId"
        class="pa-5 subtitle-2"
      >
        <v-icon color="red">mdi-exclamation</v-icon>
        {{ $_t('No insurers have been configured') }}
      </div>
      <div v-else class="py-6">
        <v-row>
          <v-col cols="12" v-if="cashPayerInsurer">
            <InsurerCard :insurer="cashPayerInsurer"></InsurerCard>
          </v-col>
          <v-col cols="12" v-for="i in insurers" v-bind:key="i.insurer_id">
            <InsurerCard :insurer="i"></InsurerCard>
          </v-col>
        </v-row>
      </div>
      <span class="body-1 grey--text font-weight-bold" v-if="false">
        {{$_t('Insurer additional info')}}
      </span>
    </div>
  </div>
</template>

<script>
import config from '@/config';
import { ref, onMounted, computed } from '@vue/composition-api';
import { bookingStore } from '@/store/booking-store';
import rest from '@/plugins/rest';
import Vue from 'vue';
import InsurerCard from '@/components/Insurer/InsurerCard';
import REFERRAL_STEPS from '@/store/consts/referral-steps';

const PUBLIC_INSURER_ID = process.env.VUE_APP_PUBLIC_INSURER_ID;

export default {
  name: 'Insurer',
  components: { InsurerCard },
  setup() {
    let loading = ref(true);
    let referralStep = computed(() => bookingStore.getState().referralStep);
    let insurers = computed(() => {
      let insurers = []
      if (
        referralStep.value === REFERRAL_STEPS.iHaveTheReferral ||
        referralStep.value === REFERRAL_STEPS.iHaveThePaperReferral
      ) {
        insurers = bookingStore.getState().insurers;
      } else {
        insurers = bookingStore
          .getState()
          .insurers.filter(
            (i) => Number(i.insurer_id) !== Number(PUBLIC_INSURER_ID)
          );
      }

      return insurers.filter(el => !config.cashPayerInsurerId || el.insurer_id !== config.cashPayerInsurerId)
    });
    let cashPayerInsurer = ref(null);

    const init = async () => {
      bookingStore.clearSelectionsOnInsurer();
      bookingStore.setShowStepper(true);
      bookingStore.setStepName('insurer');

      if (!insurers.length) {
        loading.value = true;
        const insurers = await getInsurers();
        if (!insurers) {
          Vue._notify.error('Failed to fetch insurers');
          return;
        }
        bookingStore.setInsurers(insurers);
        loading.value = false;
      }
    };

    const getInsurers = async () => {
      let insurers = null;
      /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
      try {
        const getInsurersResponse = await rest.get(
          bookingStore.getState().apiUrls.getInsurersUrl
        );
        if (getInsurersResponse.status === 'S') {
          insurers = getInsurersResponse.response.insurers;

          if (config.cashPayerInsurerId) {
            const insurer = insurers.value.find(el => el.insurer_id === config.cashPayerInsurerId)
            cashPayerInsurer.value = {
              insurer_id: config.cashPayerInsurerId,
              insurer_name_web: insurer ? insurer.insurer_name_web : Vue.prototype.$_t('Self-pay')
            };
          }
        }
      } catch {}
      return insurers;
    };

    onMounted(init);

    return {
      config,
      loading,
      insurers,
      cashPayerInsurer
    };
  }
};
</script>
