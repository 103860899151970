<template>
  <div>
    <v-row>
      <v-col cols="4" sm="5">
        <div class="mt-3 divider-line"></div>
      </v-col>
      <v-col cols="4" sm="2" class="text-center">
        <span class="text--secondary heading font-weight-bold">{{
          $_t('Not sure?')
        }}</span>
      </v-col>
      <v-col cols="4" sm="5">
        <div class="mt-3 divider-line"></div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6" lg="4" offset-lg="2">
        <v-row justify="center" align="center">
          <v-col
            cols="5"
            offset="1"
            class="text-center primary--text title cursor-pointer"
            @click="goToRoute(ROUTE_NAMES.callback.path)"
          >
            <v-icon color="primary" size="32">mdi-face-agent</v-icon>
            {{ $_t('Leave us a message') }}
          </v-col>
          <v-col cols="6" class="">
            <span class="grey--text text--darken-2 font-weight-bold">{{
              $_t('We will call you back')
            }}</span
            ><br />
            <span class="grey--text text--darken-2">{{
              $_t('and schedule a visit for you')
            }}</span>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="6" lg="4">
        <v-row justify="center" align="center">
          <v-col cols="5" offset="1" class="text-center primary--text title">
            <v-icon color="primary" size="32">mdi-phone-outline</v-icon>
            {{
              $_t('contact phone number')
            }}
          </v-col>
          <v-col cols="6" class="">
            <span class="grey--text text--darken-2 font-weight-bold" v-html="$_t('Our line is available:')"></span
            ><br />
            <span class="grey--text text--darken-2" v-html="$_t('contact hours')"></span>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ROUTE_NAMES from '@/store/consts/route-names';
import config from '@/config';

export default {
  name: 'ReferralStart',
  setup(_, context) {
    const goToRoute = (routeName) => {
      context.root.$router.push(routeName).catch(() => {});
    };
    return {
      ROUTE_NAMES,
      config,
      goToRoute,
    };
  }
};
</script>
