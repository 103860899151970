<template>
  <div>
    <ReferralStart v-if="showReferralsStart"></ReferralStart>
    <ReferralSearch v-if="showReferralsSearch"></ReferralSearch>
    <ContactUs></ContactUs>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api';
import ROUTE_NAMES from '@/store/consts/route-names';
import ReferralStart from '@/components/Referral/Start';
import ReferralSearch from '@/components/Referral/Search';
import ContactUs from '@/components/Referral/ContactUs';

export default {
  components: {
    ReferralStart,
    ReferralSearch,
    ContactUs
  },
  name: 'Referral',
  setup(_, context) {
    const showReferralsStart = computed(() =>
      context.root.$route.path.startsWith(ROUTE_NAMES.eReferralsStart.path)
    );
    const showReferralsSearch = computed(() =>
      context.root.$route.path.startsWith(ROUTE_NAMES.eReferralsSearch.path)
    );
    return {
      showReferralsStart,
      showReferralsSearch
    };
  }
};
</script>
