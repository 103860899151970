import { reactive, readonly } from '@vue/composition-api'

export class Store {
    constructor() {
        let data = this.data();
        this.state = reactive(data);
    }
    // setup(data) {
    //     console.log(data)
    // }
    getState() {
        return readonly(this.state);
    }
}
