<template>
  <div class="mb-10">
    <v-row align="stretch">
      <v-col cols="12" md="4" class="d-flex flex-column">
        <v-card
          elevation="3"
          @click="onIHaveTheEReferralClicked"
          class="flex d-flex flex-column"
        >
          <v-card-title>
            <span class="primary--text text-break">
              <v-icon color="primary" size="32" class="mr-2">
                mdi-desktop-classic
              </v-icon>
              {{ $_t('I have an e-referral') }}
            </span>
          </v-card-title>
          <v-card-text class="flex"> </v-card-text>
          <div class="divider-line"></div>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              class="text-capitalize"
              @click="onIHaveTheEReferralClicked"
            >
              {{ $_t('Choose') }}
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" md="4" class="d-flex flex-column">
        <v-card
          elevation="3"
          @click="onIHaveThePaperReferralClicked"
          class="flex d-flex flex-column"
        >
          <v-card-title>
            <span class="primary--text text-break">
              <v-icon color="primary" size="32" class="mr-2">
                mdi-file-document-edit-outline
              </v-icon>
              {{ $_t('I have a paper referral') }}
            </span>
          </v-card-title>
          <v-card-text class="flex">
            {{
              $_t(
                'Please prepare your paper referral to provide some details written on it'
              )
            }}
          </v-card-text>
          <div class="divider-line"></div>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              class="text-capitalize"
              @click="onIHaveThePaperReferralClicked"
            >
              {{ $_t('Choose') }}
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" md="4" class="d-flex flex-column">
        <v-card
          elevation="3"
          @click="onIDontHaveTheReferralClicked"
          class="flex d-flex flex-column"
        >
          <v-card-title>
            <span class="primary--text text-break">
              <v-icon color="primary" size="32" class="mr-2">
                mdi-file-outline
              </v-icon>
              {{
                $_t("I don't have a referral")
              }}
            </span>
          </v-card-title>
          <v-card-text class="flex">
            {{
              $_t(
                'MR and USG do not rquire a referral'
              )
            }}
            <br>
            {{
              $_t(
                'Please note that there is a limited number of procedures available without a referral'
              )
            }}
          </v-card-text>
          <div class="divider-line"></div>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              class="text-capitalize"
              @click="onIDontHaveTheReferralClicked"
            >
              {{ $_t('Choose') }}
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ROUTE_NAMES from '@/store/consts/route-names';
import REFERRAL_STEPS from '@/store/consts/referral-steps';
import config from '@/config';
import { onMounted } from '@vue/composition-api';
import { bookingStore } from '@/store/booking-store';

export default {
  name: 'ReferralStart',
  setup(_, context) {
    const init = () => {
      bookingStore.clearStore();
      bookingStore.setShowStepper(false);
    };

    onMounted(init);

    const goToRoute = (routeName) => {
      context.root.$router.push(routeName).catch(() => {});
    };
    const onIHaveTheEReferralClicked = () => {
      bookingStore.setReferralStep(REFERRAL_STEPS.iHaveTheReferral);
      goToRoute(ROUTE_NAMES.eReferralsSearch.path);
    };
    const onIHaveThePaperReferralClicked = () => {
      bookingStore.setReferralStep(REFERRAL_STEPS.iHaveThePaperReferral);
      goToRoute(ROUTE_NAMES.procedures.path);
    };
    const onIDontHaveTheReferralClicked = () => {
      bookingStore.setReferralStep(REFERRAL_STEPS.iDontHaveTheReferral);
      goToRoute(ROUTE_NAMES.procedures.path);
    };
    return {
      ROUTE_NAMES,
      config,
      goToRoute,
      onIHaveTheEReferralClicked,
      onIHaveThePaperReferralClicked,
      onIDontHaveTheReferralClicked
    };
  }
};
</script>
