<template>
  <div>
    <div>
      <div class="pt-12 pb-16 text-center">
        <v-row>
          <v-col cols="12" md="4" offset-md="4">
            <v-row>
              <v-col cols="12">
                <v-icon color="primary" size="75">mdi-check-circle</v-icon>
              </v-col>
              <v-col cols="12" class="title">
                {{$_t('Your appointment is booked')}}
              </v-col>
              <v-col v-if="config.alternativeConfirmationDetails" cols="12" class="subtitle-2" v-html="config.alternativeConfirmationDetails" />
              <v-col v-else cols="12" class="subtitle-2">
                {{$_t('We have sent the appointment details to you via email and SMS message')}}
              </v-col>
              <v-col cols="10" offset="1" class="text-left mt-4">
                <v-row>
                  <v-col cols="12" md="6">
                    <span class="subtitle-2">{{$_t('Clinic')}}</span><br/>
                    <span class="subtitle-1">{{ (clinic) ? clinic.clinic_name + ', ' + (clinic.clinic_address ? clinic.clinic_address : '') : $_t('N/A')}}</span>
                  </v-col>
                  <v-col cols="12" md="6">
                    <span class="subtitle-2">{{$_t('Procedure')}}</span><br/>
                    <span class="subtitle-1">{{ (procedureName) ? procedureName : $_t('N/A') }}</span>
                  </v-col>
                  <v-col cols="12" md="6">
                    <span class="subtitle-2">{{$_t('Visit date and time')}}</span><br/>
                    <span class="subtitle-1">{{ (slot) ? moment(slot.date).format(config.dateFormat) + ' ' + slot.time.slice(0, 5) : $_t('N/A') }}</span>
                  </v-col>
                  <v-col cols="12" md="6" v-if="clinic && (clinic.price_min || clinic.price_max)">
                    <span class="subtitle-2">{{$_t('To pay in clinic')}}</span><br/>

                    <span class="caption" v-if="clinic && (clinic.price_min || clinic.price_max)">
                      {{$_t('Price from') }}: {{Number(clinic.price_min).toFixed(2)}}
                      {{ $_t('currency symbol') }}
                      <br>
                      {{$_t('Your final price will be provided once the exact required procedure is confirmed during the visit')}}
                    </span>
                    <span class="subtitle-1" v-else>{{  $_t('N/A') }}</span>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </div>
    <div v-if="config.showExtendedConfirmation">
      <v-row>
        <v-col cols="12" md="4" offset-md="4" class="pa-0">
          <v-card class="ma-0">
            <v-card-text class="pa-8">
              <span class="title-2">
                {{$_t('Confirmation text 1')}}
              </span><br/><br/>
                <div class="subtitle-2">{{$_t('Confirmation text list option 1')}}</div>
                <div class="subtitle-2" v-html="$_t('Confirmation text list option 2')"></div>
              <br/>
              <span class="title-2">
                {{$_t('Confirmation text 2')}}
              </span><br/><br/>
              <v-btn text class="text-none" color="primary" v-if="selectedScan && learnMore(selectedScan.scan_code)" :href="learnMore(selectedScan.scan_code)" target="_blank">{{$_t('Learn more')}}</v-btn><br/>
              <div class="divider-line mt-1"></div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <div>
      <v-row>
        <v-col cols="12" md="4" offset-md="4" class="pa-0 mb-8 text-sm-center">
          <v-btn large @click="homepage">
            {{ $_t('Start again') }}
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { bookingStore } from '@/store/booking-store'
import {ref, onMounted, computed } from '@vue/composition-api'
import config from '@/config'
import moment from 'moment/moment'
import Vue from 'vue';

export default {
  name: 'Confirmation',
  components: {
  },
  setup() {
    const loading = ref(true)
    const clinic = computed(() => bookingStore.getState().clinic)
    const refProcedure = computed(() => bookingStore.getState().referralProcedure)
    const procedureName = ref('')
    const bodyPartId = computed(() => bookingStore.getState().bodyPartId)
    const bodyParts = computed(() => bookingStore.getState().bodyParts)
    const procedures = computed(() => bookingStore.getState().procedures)
    const procedureId = computed(() => bookingStore.getState().procedureId)
    const scanId = computed(() => bookingStore.getState().scanId)
    const scans = computed(() => bookingStore.getState().scans)
    const slot = computed(() => bookingStore.getState().slot)
    const selectedScan = ref(null)

    const init = () => {
      bookingStore.setStep({ name: 'confirmation', showStepper: false, title: Vue.prototype.$_t('Confirmation'), subTitle: Vue.prototype.$_t('Thank you for choosing ') + config.customerName });
      loading.value = false
      if (!refProcedure.value) {
        selectedScan.value = scans.value.find(obj => obj.scan_id === scanId.value)

        if (config.useProcedures && procedures.value) {
          const procedure = (procedures.value[scanId.value] || []).find((obj) => {
            return obj.procedure_id === procedureId.value;
          });

          if (procedure) {
            procedureName.value = procedure.procedure_name
          }
        } else {
          const bodyPart = (bodyParts.value[scanId.value] || []).find((obj) => {
            return obj.body_part_id === bodyPartId.value;
          });

          if (bodyPart) {
            procedureName.value = selectedScan.value.scan_name_web + ' - ' + bodyPart.body_part_name_web
          }
        }
      } else {
        procedureName.value = refProcedure.value.procedure_name
        selectedScan.value = {
          scan_code: refProcedure.value.scan_code
        }
      }

      bookingStore.setBookingConfirmed(true)
      // clear appointment details
      bookingStore.setAppointmentProcedureId(null)

      if (config.googleGTMConfirmation) {
        initGoogle()
      }
    }

    const initGoogle = () => {
      (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({'gtm.start': new Date().getTime(), event: 'gtm.js'});
        var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s),
            dl = l != 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', config.googleGTMConfirmation);
    }


    onMounted(init)

    const homepage = () => {
      window.location = config.homepageLink
    }

    const learnMore = (scanCode) => {
      if (scanCode === 'MR') {
        scanCode = 'MRI'
      }
      if (scanCode === 'TK') {
        scanCode = 'CT'
      }

      return config.scanLearnMoreLinks[scanCode]
    }

    return {
      loading,
      clinic,
      procedureName,
      config,
      homepage,
      selectedScan,
      learnMore,
      moment,
      slot
    }
  }
}
</script>
