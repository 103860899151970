import Vue from 'vue'
import VueRouter from 'vue-router'
import Booking from '../views/Booking.vue'
import config from '@/config'
import ROUTE_NAMES from '@/store/consts/route-names';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: config.startPage
  },
  {
    path: '/scan/:scanCode',
    name: 'Booking',
    component: Booking
  },
  {
    path: '/scan/:scanCode/bodypart/:bodyPartCode',
    name: 'Booking',
    component: Booking
  },
  {
    path: '/scan/:scanCode/procedure/:procedureCode',
    name: 'Booking',
    component: Booking
  },
  {
    path: '*',
    name: 'Booking',
    component: Booking
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (from.path === ROUTE_NAMES.procedures.path && (to.path !== ROUTE_NAMES.clinics.path && to.path !== ROUTE_NAMES.insurers.path)) {
    window.location.reload(true)
    return false;
  } else if (from.path === ROUTE_NAMES.confirmation.path) {
    return false;
  }

  return next()
})

export default router
