<template>
  <div>
    <div v-if="loading" class="text-center pt-12">
      <v-progress-circular
        :size="75"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
    <div v-else>
      <span class="title grey--text text--darken-1">{{
        $_t('Select procedure')
      }}</span>
      <v-btn
        v-if="selectedScanId && scansFilteredByReferralStep.length > 1"
        color="primary"
        class="mt-sm-n2 ml-sm-4 text-none"
        small
        @click="clearSelection"
        >{{ $_t('Select a different procedure') }}</v-btn
      >
      <div v-if="scans.length" class="py-6">
        <v-row>
          <v-col cols="12" md="6">
            <v-row
              v-for="s in scansFilteredByReferralStep"
              v-bind:key="s.scan_id"
            >
              <v-col
                v-if="!selectedScanId || selectedScanId === s.scan_id"
                cols="12"
              >
                <v-btn
                  x-large
                  @click="onScanClicked(s.scan_id, s.require_full_web_booking_process)"
                  :outlined="s.scan_id !== selectedScanId"
                  color="primary"
                  class="full-width"
                >
                  {{ s.scan_name_web }}
                  <v-spacer></v-spacer>
                  <v-icon v-if="s.scan_id !== selectedScanId"
                    >mdi-chevron-right</v-icon
                  >
                  <v-icon v-else>mdi-chevron-down</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6" v-if="selectedScanId" class="mt-2">
            <v-row v-if="!config.useProcedures">
              <v-col
                cols="12"
                v-for="b in bodyParts[selectedScanId]"
                v-bind:key="b.body_part_id"
                class="py-0 my-1"
              >
                <v-btn
                  x-large
                  @click="onBodyPartClicked(b.body_part_id)"
                  :outlined="b.body_part_id !== selectedBodyPartId"
                  color="primary"
                  class="full-width"
                >
                  {{ b.body_part_name_web }}
                  <v-spacer></v-spacer>
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row v-else>
              <template v-if="showBodyParts">
                <v-col
                    cols="12"
                    v-for="b in bodyParts[selectedScanId]"
                    v-bind:key="b.bodyPartId"
                    class="py-0 my-1"
                >
                  <v-btn
                      x-large
                      @click="onBodyPartClicked(b.bodyPartId)"
                      outlined
                      color="primary"
                      class="full-width"
                  >
                    {{ b.bodyPartName }}
                    <v-spacer></v-spacer>
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </v-col>
              </template>
              <template v-else>
                <v-col
                    cols="12"
                    v-if="selectedBodyPartId"
                    class="py-0 my-1"
                >
                  <v-btn
                      x-large
                      color="primary"
                      class="full-width"
                      @click="clearBodyPart"
                  >
                    {{ selectedBodyPartName }}
                    <v-spacer />
                    <v-icon>mdi-chevron-left</v-icon>
                  </v-btn>
                </v-col>
                <v-col
                    v-for="p in proceduresList"
                    cols="12"
                    v-bind:key="p.procedure_id"
                    class="py-0 my-1"
                >
                  <v-btn
                      x-large
                      @click="onProcedureClicked(p.procedure_id)"
                      :outlined="p.procedure_id !== selectedProcedureId"
                      color="primary"
                      class="full-width"
                  >
                    {{ p.procedure_name }}
                    <v-spacer></v-spacer>
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </v-col>
              </template>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import config from '@/config';
import { bookingStore } from '@/store/booking-store';
import { ref, computed, watch, onMounted } from '@vue/composition-api';
import rest from '@/plugins/rest';
import Vue from 'vue';
import ROUTE_NAMES from '@/store/consts/route-names';
import REFERRAL_STEPS from '@/store/consts/referral-steps';
import NO_REFERRAL_ALLOWED_SCANS from '@/store/consts/no-referral-allowed-scans';

export default {
  name: 'Procedure',
  props: ['proceedToInsurers'],
  setup(props, context) {
    let loading = ref(true);
    let scans = computed(() => bookingStore.getState().scans);
    let bodyParts = computed(() => bookingStore.getState().bodyParts);
    let procedures = computed(() => bookingStore.getState().procedures);
    let selectedScanId = computed(() => bookingStore.getState().scanId);
    let selectedBodyPartId = computed(() => bookingStore.getState().bodyPartId);
    let selectedProcedureId = computed(() => bookingStore.getState().procedureId);
    let scansFilteredByReferralStep = computed(() => {
      const referralStep = bookingStore.getState().referralStep;
      if (referralStep === REFERRAL_STEPS.iDontHaveTheReferral) {
        return scans.value.filter((s) =>
          NO_REFERRAL_ALLOWED_SCANS.includes(s.scan_id)
        );
      } else {
        return scans.value;
      }
    });

    const showBodyParts = computed(() => {
      return selectedScanId.value && !selectedBodyPartId.value && !selectedProcedureId.value && bodyParts.value && bodyParts.value[selectedScanId.value].length > 1
    })

    const selectedBodyPartName = computed(() => {
      let bodyPart
      if (selectedBodyPartId.value && bodyParts.value && bodyParts.value[selectedScanId.value].length) {
        bodyPart = bodyParts.value[selectedScanId.value].find(el => el.bodyPartId === selectedBodyPartId.value)
      }

      return typeof bodyPart !== 'undefined' ? bodyPart.bodyPartName : ''
    })

    const proceduresList = computed(() => {
      return procedures.value && procedures.value[selectedScanId.value].length ? procedures.value[selectedScanId.value].filter(el => !selectedBodyPartId.value || el.body_part_id === selectedBodyPartId.value) : []
    })

    const init = async () => {
      bookingStore.clearSelectionsOnProcedure();
      bookingStore.setShowStepper(true);
      bookingStore.setStepName('procedure');
      if (!scans.length) {
        loading.value = true;
        const scans = await getScans();
        if (!scans) {
          Vue._notify.error('Failed to fetch scans');
          return;
        }
        bookingStore.setScans(scans);
        loading.value = false;
      }
    };

    const onScanClicked = async (scanId, requireFullWebBookingProcess) => {
      if (requireFullWebBookingProcess === '0') {
        context.root.$router.push(ROUTE_NAMES.callback.path);
        return;
      }
      if (scanId !== selectedScanId.value) {
        loading.value = true;
      }
      const appointmentProcedureId = await setScan(scanId);
      if (!appointmentProcedureId) {
        Vue._notify.error('Could not assign the selected scan');
        return;
      }
      bookingStore.setScanId(scanId);
      bookingStore.setAppointmentProcedureId(
        appointmentProcedureId
      )

      if (!config.useProcedures) {
        if (!bodyParts[scanId]) {
          const bodyParts = await getBodyParts(scanId)
          if (!bodyParts) {
            Vue._notify.error('Failed to fetch body parts')
            return
          }
          const existingBodyParts = bookingStore.getState().bodyParts
          existingBodyParts[scanId] = bodyParts
          bookingStore.setBodyParts(existingBodyParts)

          if (bookingStore.getState().initCode) {
            const bodyPart = existingBodyParts[scanId].find(el => el.body_part_code.toLowerCase().trim() === bookingStore.getState().initCode.toLowerCase().trim())

            if (bodyPart) {
              await onBodyPartClicked(bodyPart.body_part_id)
            }

            bookingStore.setInitCode('')

            return
          }


          loading.value = false;
        }
      } else {
        if (!procedures[scanId]) {
          const procedures = await getProcedures(scanId)
          if (!procedures) {
            Vue._notify.error('Failed to fetch procedures')
            return
          }
          const existingProcedures = bookingStore.getState().procedures
          existingProcedures[scanId] = procedures
          bookingStore.setProcedures(existingProcedures)

          const scanBodyParts = []

          procedures.forEach((procedure) => {
            if (typeof scanBodyParts[procedure.body_part_id] === 'undefined') {
              scanBodyParts[procedure.body_part_id] = {
                bodyPartId: procedure.body_part_id,
                bodyPartName: procedure.body_part_name_web
              }
            }
          })

          bookingStore.setBodyPartsForScan(scanId, Object.values(scanBodyParts))

          if (bookingStore.getState().initCode) {
            const procedure = existingProcedures[scanId].find(el => el.procedure_code.toLowerCase().trim() === bookingStore.getState().initCode.toLowerCase().trim())

            if (procedure) {
              await onProcedureClicked(procedure.procedure_id)
            }

            bookingStore.setInitCode('')

            return
          }


          loading.value = false;
        }
      }
    }

    const onProcedureClicked = async (procedureId) => {
      if (procedureId !== selectedProcedureId.value) {
        loading.value = true;
      }
      const appointmentProcedureId = await setProcedure(procedureId);

      if (!appointmentProcedureId) {
        Vue._notify.error('Could not assign the selected procedure');
        loading.value = false
        return;
      }
      bookingStore.setProcedureId(procedureId);
      goToInsurers();
    }

    const onBodyPartClicked = async (bodyPartId) => {
      bookingStore.setBodyPartId(bodyPartId);

      if (!config.useProcedures) {
        goToInsurers();
      }
    };

    const getScans = async () => {
      let scans = null;
      /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
      try {
        const getScansResponse = await rest.get(
          bookingStore.getState().apiUrls.getScansUrl
        );
        if (getScansResponse.status === 'S') {
          scans = getScansResponse.response.scans;
        }
      } catch {}
      return scans;
    };

    const setScan = async (scanId) => {
      let appointmentProcedureId = null;
      /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
      try {
        const setScanResponse = await rest.get(
          bookingStore.getState().apiUrls.setScanIdUrl,
          { scanId: scanId }
        );
        if (setScanResponse.status === 'S') {
          appointmentProcedureId = setScanResponse.response.appointmentProcedureId;
        }
      } catch {}
      return appointmentProcedureId;
    }

    const setProcedure = async (procedureId) => {
      try {
        const setProcedureResponse = await rest.get(
          bookingStore.getState().apiUrls.setProcedureIdUrl,
          {
            procedureId: procedureId
          }
        );
        if (setProcedureResponse.status !== 'S') {
          return false;
        }
      } catch {}
      return true;
    }

    const getBodyParts = async (scanId) => {
      let bodyParts = null;
      /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
      try {
        const getBodyPartsResponse = await rest.get(
          bookingStore.getState().apiUrls.getBodyPartsUrl,
          { scanId: scanId }
        );
        if (getBodyPartsResponse.status === 'S') {
          bodyParts = getBodyPartsResponse.response.bodyParts;
        }
      } catch {}
      return bodyParts;
    }

    const getProcedures = async (scanId) => {
      let procedures = null
      /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
      try {
        const getProceduresResponse = await rest.get(
          bookingStore.getState().apiUrls.getProceduresUrl,
          { scanId: scanId }
        );
        if (getProceduresResponse.status === 'S') {
          procedures = getProceduresResponse.response.procedures;
        }
      } catch {}
      return procedures
    }

    const goToInsurers = () => {
      if (props.proceedToInsurers) {
        context.root.$router.push(ROUTE_NAMES.insurers.path);
      }
    }

    const clearSelection = () => {
      bookingStore.setScanId(null)
      bookingStore.setBodyPartId(null)
      bookingStore.setProcedureId(null)
    }

    const clearBodyPart = () => {
      bookingStore.setBodyPartId(null)
      bookingStore.setProcedureId(null)
    }

    watch(scansFilteredByReferralStep, (newValue, oldValue) => {
      if (bookingStore.getState().initScanCode) {
        const scan = newValue.find(el => el.scan_code.toLowerCase().trim() === bookingStore.getState().initScanCode.toLowerCase().trim())

        if (scan) {
          onScanClicked(scan.scan_id)
        }

        bookingStore.setInitScanCode('')

        return
      }

      if (!oldValue || oldValue.length === 0 && newValue && newValue.length === 1) {
        onScanClicked(newValue[0].scan_id)
      }
    })

    onMounted(init);

    return {
      loading,
      config,
      scans,
      scansFilteredByReferralStep,
      onScanClicked,
      bodyParts,
      procedures,
      onBodyPartClicked,
      onProcedureClicked,
      selectedScanId,
      selectedBodyPartId,
      selectedProcedureId,
      clearSelection,
      showBodyParts,
      selectedBodyPartName,
      proceduresList,
      clearBodyPart
    }
  }
}
</script>
