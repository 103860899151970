import { Store } from './main';
import rest from '@/plugins/rest';
import Vue from 'vue'

class BookingStore extends Store {
  data() {
    return {
      referralStep: null,
      referral: null,
      referralProcedure: null,
      step: { name: 'start', showStepper: false, title: '', subTitle: '' },
      apiUrls: {},
      appointmentAccessCode: null,
      appointmentProcedureId: null,
      scans: [],
      scanId: null,
      bodyParts: [],
      procedures: [],
      bodyPartId: null,
      procedureId: null,
      referralLetterRequired: false,
      scanRegions: [],
      scanRegionId: null,
      insurers: [],
      insurer: null,
      clinicId: null,
      clinic: null,
      clinics: {},
      slot: null,
      scheduleDialogOpen: false,
      patientForm: {
        submitted: false,
        data: {}
      },
      initScanCode: '',
      initCode: '',
      bookingConfirmed: false
    };
  }
  setBookingConfirmed(bookingConfirmed) {
    this.state.bookingConfirmed = bookingConfirmed
  }
  setReferralStep(referralStep) {
    this.state.referralStep = referralStep;
  }
  setReferral(referral) {
    this.state.referral = referral;
  }
  setReferralProcedure(procedure) {
    this.state.referralProcedure = procedure;
  }
  setStep(step) {
    this.state.step = step;
  }
  setStepName(name) {
    this.state.step.name = name;
  }
  setShowStepper(showStepper) {
    this.state.step.showStepper = showStepper;
  }
  setStepTitle(title) {
    this.state.step.title = title;
  }
  setStepSubTitle(subTitle) {
    this.state.step.subTitle = subTitle;
  }
  setApiUrls(apiUrls) {
    this.state.apiUrls = apiUrls;
  }
  setAppointmentAccessCode(appointmentAccessCode) {
    this.state.appointmentAccessCode = appointmentAccessCode;
  }
  setAppointmentProcedureId(appointmentProcedureId) {
    this.state.appointmentProcedureId = appointmentProcedureId;
  }
  setScans(scans) {
    this.state.scans = scans;
  }
  setScanId(scanId) {
    this.state.scanId = scanId;
  }
  setBodyPartId(bodyPartId) {
    this.state.bodyPartId = bodyPartId;
  }
  setScanRegionId(scanRegionId) {
    this.state.scanRegionId = scanRegionId;
  }
  setBodyParts(bodyParts) {
    this.state.bodyParts = bodyParts;
  }
  setBodyPartsForScan(scanId, bodyParts) {
    this.state.bodyParts[scanId] = bodyParts;
  }
  setProcedures(procedures) {
    this.state.procedures = procedures;
  }
  setProcedureId(procedureId) {
    this.state.procedureId = procedureId;
  }
  setScanRegions(scanRegions) {
    this.state.scanRegions = scanRegions;
  }
  setInsurers(insurers) {
    this.state.insurers = insurers;
  }
  setInsurer(insurer) {
    this.state.insurer = insurer;
  }
  setClinics(clinics) {
    this.state.clinics = clinics;
  }
  setClinicsForBodyPartAndRegion(bodyPartId, regionId, clinics) {

    if (typeof this.state.clinics[bodyPartId] === 'undefined') {
      this.state.clinics[bodyPartId] = {}
    }
    let regionClinics = {}
    regionClinics[regionId] = clinics
    Vue.set(this.state.clinics, bodyPartId, regionClinics)
    this.state.clinics = {...this.state.clinics}
  }
  setClinicId(clinicId) {
    this.state.clinicId = clinicId;
  }
  setClinic(clinic) {
    this.state.clinic = clinic;
  }
  setSlot(slot) {
    this.state.slot = slot;
  }
  setScheduleDialogOpen(open) {
    this.state.scheduleDialogOpen = open;
  }
  setPatientForm(obj) {
    this.state.patientForm = obj
  }
  setPatientFormSubmitted(submitted) {
    this.state.patientForm.submitted = submitted
  }
  setPatientFormData(data) {
    this.state.patientForm.data = data
  }
  setInitScanCode(scanCode) {
    this.state.initScanCode = scanCode || ''
  }
  setInitCode(code) {
    this.state.initCode = code || ''
  }
  clearStore() {
    // # DO NOT CLEAR APIS
    this.setReferralStep(null);
    this.setReferral(null);
    this.setReferralProcedure(null);
    this.setAppointmentAccessCode(null);
    this.setAppointmentProcedureId(null);
    this.setScans([]);
    this.setScanId(null);
    this.setBodyParts([]);
    this.setBodyPartId(null);
    this.setScanRegions([]);
    this.setInsurer(null);
    this.setInsurers([]);
    this.setClinicId(null);
    this.setClinic(null);
    this.setClinics([]);
    this.setSlot(null);
    this.setPatientForm({ submitted: false, data: {}});
    this.setStep({ name: 'start', showStepper: false, title: '', subTitle: '' });
  }
  clearSelectionsOnProcedure() {
    // # DO NOT CLEAR APIS
    this.setScanId(null);
    this.setBodyPartId(null);
    this.setInsurer(null);
    this.setScanRegionId(null);
    this.setClinicId(null);
    this.setClinic(null);
    this.setSlot(null);
    this.setScheduleDialogOpen(false);
    this.setPatientFormSubmitted(false);
  }
  clearSelectionsOnInsurer() {
    // # DO NOT CLEAR APIS
    this.setInsurer(null);
    this.setScanRegionId(null);
    this.setClinicId(null);
    this.setClinic(null);
    this.setSlot(null);
    this.setScheduleDialogOpen(false);
    this.setPatientFormSubmitted(false);
  }
  clearSelectionsOnClinic() {
    // # DO NOT CLEAR APIS
    this.setScanRegionId(null);
    this.setClinicId(null);
    this.setClinic(null);
    this.setSlot(null);
    this.setScheduleDialogOpen(false);
    this.setPatientFormSubmitted(false);
  }
  async getApiUrls(appointmentAccessCode, scanId) {
    const response = await rest
      .get(
        '',
        appointmentAccessCode ? { a: appointmentAccessCode, scanIds: [scanId], erl: true } : {},
      )
      this.setApiUrls(response.response.urls);
      return response.status;
  }
  getProcedure() {
    const procedures = this.state.procedures || {}
    const proceduresForScan = procedures[this.state.scanId] || []
    return proceduresForScan.find(el => el.procedure_id === this.state.procedureId) || {}
  }
}

export const bookingStore = new BookingStore();
