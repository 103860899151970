import Vue from 'vue'

const apiUrl = process.env.VUE_APP_WEBBOOKING_API_URL

export default {
  get (endpoint, data, silent) {
    return new Promise((resolve, reject) => {
      endpoint += this.toUrlEncoded(data)
      fetch(
        endpoint.match(/^http/) ? endpoint : apiUrl + endpoint)
        .then(response => {
          if (response.status === 200) {
            return resolve(response.json())
          }
          response.json().then(response => {
            const message = response && response.errors && response.errors[0] || 'Request failed'
            if (!silent) {
              Vue._notify.error(message, 'GET request error', 'rest')
            }
            reject(message)
          })
        })
        .catch(error => reject(error))
    })
  },
  post (endpoint, data, silent) {
    return new Promise((resolve, reject) => {
      fetch(
        endpoint.match(/^http/) ? endpoint : apiUrl + endpoint,
        {
          method: 'POST',
          body: JSON.stringify(data),
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json;charset=UTF-8'
          }
        })
        .then(response => {
          if (response.status === 200) {
            return resolve(response.json())
          }
          response.json().then(response => {
            const message = response && response.errors && response.errors[0] || 'Request failed'
            if (!silent) {
              Vue._notify.error(message, 'POST request error', 'rest')
            }
            reject(message)
          })
        })
        .catch(error => reject(error))
    })
  },
  put (endpoint, data, silent) {
    return new Promise((resolve, reject) => {
      fetch(
        endpoint.match(/^http/) ? endpoint : apiUrl + endpoint,
        {
          method: 'PUT',
          body: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json;charset=UTF-8'
          }
        })
        .then(response => {
          if (response.status === 200) {
            return resolve(response.json())
          }
          response.json().then(response => {
            const message = response && response.errors && response.errors[0] || 'Request failed'
            if (!silent) {
              Vue._notify.error(message, 'PUT request error', 'rest')
            }
            reject(message)
          })
        })
        .catch(error => reject(error))
    })
  },
  delete (endpoint, silent) {
    return new Promise((resolve, reject) => {
      fetch(
        endpoint.match(/^http/) ? endpoint : apiUrl + endpoint,
        {
          method: 'DELETE',
          headers: {}
        })
        .then(response => {
          if (response.status === 200) {
            return resolve(response.json())
          }
          response.json().then(response => {
            const message = response && response.errors && response.errors[0] || 'Request failed'
            if (!silent) {
              Vue._notify.error(message, 'DELETE request error', 'rest')
            }
            reject(message)
          })
        })
        .catch(error => reject(error))
    })
  },
  toUrlEncoded (params, keys = [], isArray = false) {
    if (!params) {
      return ''
    }
    const p = Object.keys(params).map(key => {
      const val = params[key]

      if ('[object Object]' === Object.prototype.toString.call(val) || Array.isArray(val)) {
        if (Array.isArray(params)) {
          keys.push('')
        } else {
          keys.push(key)
        }
        return this.toUrlEncoded(val, keys, Array.isArray(val))
      } else {
        let tKey = key

        if (keys.length > 0) {
          const tKeys = isArray ? keys : [...keys, key]
          tKey = tKeys.reduce((str, k) => {
            return '' === str ? k : `${ str }[${ k }]`
          }, '')
        }
        if (isArray) {
          return `${ tKey }[]=${ val }`
        } else {
          return `${ tKey }=${ val }`
        }

      }
    }).join('&')

    keys.pop()
    return '&' + p
  }
}
