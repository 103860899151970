import { render, staticRenderFns } from "./BookingSteps.vue?vue&type=template&id=d84d007a&scoped=true&"
import script from "./BookingSteps.vue?vue&type=script&lang=js&"
export * from "./BookingSteps.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  var style0 = require("./BookingSteps.vue?vue&type=style&index=0&id=d84d007a&scoped=true&lang=css&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "d84d007a",
  null
  ,true
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VStepper } from 'vuetify/lib/components/VStepper';
import { VStepperHeader } from 'vuetify/lib/components/VStepper';
import { VStepperStep } from 'vuetify/lib/components/VStepper';
installComponents(component, {VChip,VDivider,VStepper,VStepperHeader,VStepperStep})
