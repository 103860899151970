<template>
  <div>
    <div v-if="loading" class="text-center pt-12">
      <v-progress-circular
        :size="75"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
    <div v-else>
      <span class="title grey--text text--darken-1" v-if="!showNoClinics">{{
        $_t('Select region')
      }}</span>
      <v-btn
        v-if="selectedScanRegionId && scanRegions[selectedScanId] && scanRegions[selectedScanId][selectedBodyPartId] && scanRegions[selectedScanId][selectedBodyPartId].length > 1"
        color="primary"
        class="mt-sm-n2 ml-sm-4 text-none"
        small
        @click="clearSelection"
        >{{ $_t('Select different region') }}</v-btn
      >
      <div
        v-if="
          scanRegions[selectedScanId] &&
            scanRegions[selectedScanId][selectedBodyPartId]
        "
        class="py-6"
      >
        <v-row>
          <v-col cols="12" md="6">
            <v-row
              v-for="r in scanRegions[selectedScanId][selectedBodyPartId]"
              v-bind:key="r.region_id"
            >
              <v-col
                v-if="
                  !selectedScanRegionId || selectedScanRegionId === r.region_id
                "
                cols="12"
              >
                <v-btn
                  x-large
                  @click="onScanRegionClicked(r.region_id)"
                  :outlined="r.region_id !== selectedScanRegionId"
                  color="primary"
                  class="full-width"
                >
                  {{ r.region_name }}
                  <v-spacer></v-spacer>
                  <v-icon v-if="r.region_id !== selectedScanRegionId"
                    >mdi-chevron-right</v-icon
                  >
                  <v-icon v-else>mdi-chevron-down</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col v-if="loadingClinics" cols="12" md="6" class="text-center">
            <v-progress-circular
                :size="75"
                color="primary"
                indeterminate
            ></v-progress-circular>
          </v-col>
          <v-col cols="12" md="6">
            <div
              v-if="!loadingClinics && clinics[selectedBodyPartId] && clinics[selectedBodyPartId][selectedScanRegionId]"
            >
              <v-row
                v-for="c in clinics[selectedBodyPartId][selectedScanRegionId]"
                v-bind:key="c.clinic_id"
                class="mb-5"
              >
                <v-col cols="12">
                  <v-card>
                    <v-card-title>{{ c.clinic_name }}</v-card-title>
                    <v-card-text>
                      <div class="divider-line"></div>
                      <div class="pa-2 pl-5">
                        <span>{{ $_t('Earliest appointment') }}:</span>
                        <span class="font-weight-bold text-lowercase">
                          {{
                            moment(c.availability_date).format(
                              config.dateFormat
                            )
                          }}&nbsp;
                          {{ c.availability_start.substring(0, 5) }}&nbsp; ({{
                            $_t('in')
                          }}
                          {{ daysLeft(new Date(c.availability_date)) }}
                          {{
                            daysLeft(new Date(c.availability_date)) === 1
                              ? $_t('day')
                              : $_t('days')
                          }})
                        </span>
                      </div>
                      <div class="divider-line"></div>
                      <div class="pa-2 pl-5">
                        <span>{{ $_t('Address') }}:&nbsp;</span>
                        <span class="font-weight-bold">{{
                          c.clinic_address
                        }}</span>
                      </div>
                      <div v-if="c.price_min" class="divider-line"></div>
                      <div v-if="c.price_min" class="pa-2 pl-5">
                        <span>{{ $_t('Price from') }}:&nbsp;</span>
                        <span class="font-weight-bold"
                        >{{ config.currencyPrefix }}</span
                        ><span class="font-weight-bold"
                          >{{ Number(c.price_min).toFixed(2) }} </span
                        ><span class="font-weight-bold"
                          >{{ config.currencySuffix }}</span
                        >
                      </div>
                      <div class="divider-line"></div>
                    </v-card-text>
                    <v-card-actions class="pt-0">
                      <v-btn
                        @click="showMap(c)"
                        class="text-none"
                        color="primary"
                        text
                        small
                      >
                        {{ $_t('Show on map') }}
                        <v-icon>mdi-map-marker-outline</v-icon>
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn
                        @click="onClinicClicked(c)"
                        class="text-none"
                        color="primary"
                        text
                        large
                      >
                        <span
                          >{{ $_t('Schedule a visit') }} /
                          <br class="d-block d-md-none" />{{
                            $_t('Check availability')
                          }}</span
                        >
                        <v-icon class="ml-1">mdi-calendar-month</v-icon>
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
              <p v-if="showPriceInfo" class="subtitle-2">
                * {{ $_t('Price can change based on ...') }}
                <br />
                {{ $_t('Information not the offer with respect to ...') }}
              </p>
            </div>
          </v-col>
        </v-row>
      </div>
      <div v-if="showNoClinics">
        <v-row
            class="mb-5"
        >
          <v-col cols="12">
            <v-card>
              <v-card-title>
                {{ $_t('No availability') }}
              </v-card-title>
              <v-card-text v-if="config.labelNoAvailability">
                {{ config.labelNoAvailability }}
              </v-card-text>
              <v-card-text v-else>
                {{ $_t('We are sorry but we could not find any availability online. We may still be able to offer you an appointment. Please contact us on') }}&nbsp;{{ config.contactPhoneNumber }}
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </div>
    <ClinicSchedule v-if="selectedClinicId"></ClinicSchedule>
  </div>
</template>

<script>
import config from '@/config';
import { ref, onMounted, computed } from '@vue/composition-api';
import { bookingStore } from '@/store/booking-store';
import rest from '@/plugins/rest';
import Vue from 'vue';
import ClinicSchedule from '@/components/Clinic/ClinicSchedule';
import moment from 'moment';

export default {
  name: 'Clinic',
  components: { ClinicSchedule },
  setup() {
    let loading = ref(true)
    let loadingClinics = ref(false)
    let scanRegions = computed(() => bookingStore.getState().scanRegions)
    let clinics = computed(() => bookingStore.getState().clinics)
    let selectedScanId = computed(() => bookingStore.getState().scanId)
    let selectedBodyPartId = computed(() => bookingStore.getState().bodyPartId)
    let selectedInsurer = computed(() => bookingStore.getState().insurer)
    let selectedScanRegionId = computed(
      () => bookingStore.getState().scanRegionId
    );
    let selectedClinicId = computed(() => bookingStore.getState().clinicId)
    const showPriceInfo = ref(false)
    /*let showPriceInfo = computed(() => {
      if (!clinics.value) {
        return false;
      }
      const clinicsArr = Object.values(
        clinics.value[selectedBodyPartId.value][selectedScanRegionId.value]
      );

      const clinicsWithPrice = clinicsArr.filter((c) => c.price_min);
      console.log(clinicsWithPrice)
      return clinicsWithPrice && clinicsWithPrice.length > 0;
    });*/

    const showNoClinics = ref(false)

    const init = async () => {
      bookingStore.clearSelectionsOnClinic();
      bookingStore.setShowStepper(true);
      bookingStore.setStepName('clinic');
      if (
        !scanRegions[selectedScanId.value] ||
        !scanRegions[selectedScanId.value][selectedBodyPartId.value]
      ) {
        loading.value = true;
        showNoClinics.value = false

        const scanRegions = await getScanRegions(
          selectedScanId.value,
          selectedBodyPartId.value
        );
        if (!scanRegions || !Array.isArray(scanRegions)) {
          Vue._notify.error('Failed to fetch scan regions');
          return
        }

        if (!scanRegions.length) {
          loading.value = false
          showNoClinics.value = true
          return
        }

        const existingScanRegions = bookingStore.getState().scanRegions;
        existingScanRegions[selectedScanId.value] = [];
        existingScanRegions[selectedScanId.value][
          selectedBodyPartId.value
        ] = scanRegions;
        bookingStore.setScanRegions(existingScanRegions);
        loading.value = false;
      }
    };

    const onScanRegionClicked = async (scanRegionId) => {
      if (scanRegionId === selectedScanRegionId.value) {
        return;
      }
      showNoClinics.value = false
      bookingStore.setScanRegionId(scanRegionId);
      if (
        !clinics[selectedBodyPartId.value] ||
        !clinics[selectedBodyPartId.value][scanRegionId]
      ) {
        loading.value = true;

        const clinics = await getClinics(
          selectedBodyPartId.value,
          selectedInsurer.value.insurer_id,
          scanRegionId
        );
        if (!clinics) {
          Vue._notify.error('Failed to fetch clinics');
          return;
        }

        if (!Object.keys(clinics).length) {
          showNoClinics.value = true
        }

        bookingStore.setClinicsForBodyPartAndRegion(selectedBodyPartId.value, scanRegionId, clinics);
        loading.value = false;
      }
    };

    const onClinicClicked = async (clinic) => {
      bookingStore.setScheduleDialogOpen(true);
      bookingStore.setClinicId(clinic.clinic_id);
      bookingStore.setClinic(clinic);
    };

    const clearSelection = () => {
      bookingStore.setScanRegionId(null);
      bookingStore.setClinicId(null);
      bookingStore.setClinic(null);
    };

    const dayNumberToText = (day) => {
      switch (day) {
        case 0:
          return 'Monday';
        case 1:
          return 'Tuesday';
        case 2:
          return 'Wednesday';
        case 3:
          return 'Thursday';
        case 4:
          return 'Friday';
        case 5:
          return 'Saturday';
        case 6:
          return 'Sunday';
      }
    };

    const daysLeft = (date2) => {
      const date1 = new Date();
      const _MS_PER_DAY = 1000 * 60 * 60 * 24;

      const utc1 = Date.UTC(
        date1.getFullYear(),
        date1.getMonth(),
        date1.getDate()
      );
      const utc2 = Date.UTC(
        date2.getFullYear(),
        date2.getMonth(),
        date2.getDate()
      );

      return Math.floor((utc2 - utc1) / _MS_PER_DAY);
    };

    const getScanRegions = async (scanId, bodyPartId) => {
      let scanRegions = null;
      /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
      try {
        const scanRegionsResponse = await rest.get(
          bookingStore.getState().apiUrls.getScanRegionsUrl,
          {
            scanId: scanId,
            bodyPartId: bodyPartId
          }
        );
        if (scanRegionsResponse.status === 'S') {
          scanRegions = scanRegionsResponse.response.scanRegions;
        }
      } catch {}

      if (scanRegions && scanRegions.length === 1) {
        Vue.nextTick(() => {
          onScanRegionClicked(scanRegions[0].region_id)
        })
      }
      return scanRegions;
    };

    const getClinics = async (bodyPartId, insurerId, scanRegionId) => {
      let clinics = null;
      /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
      loadingClinics.value = true;
      try {
        const clinicsResponse = await rest.get(
          bookingStore.getState().apiUrls.getClinicsUrl,
          {
            bodyPartId: bodyPartId,
            regionId: scanRegionId,
            insurerId: insurerId
          }
        );
        if (clinicsResponse.status === 'S') {
          clinics = clinicsResponse.response.clinics;
        }
      } catch {}
      loadingClinics.value = false;
      return clinics;
    };

    const showMap = (clinic) => {
      window.open(
        'https://www.google.com/maps/search/?api=1&query=' +
          clinic.clinic_lat +
          ',' +
          clinic.clinic_lng,
        '_blank'
      );
    };

    onMounted(init);

    return {
      config,
      loading,
      scanRegions,
      clinics,
      onScanRegionClicked,
      onClinicClicked,
      selectedScanId,
      selectedBodyPartId,
      selectedScanRegionId,
      selectedClinicId,
      showPriceInfo,
      dayNumberToText,
      daysLeft,
      showMap,
      clearSelection,
      moment,
      showNoClinics,
      loadingClinics
    };
  }
};
</script>

<style scoped></style>
