<template>
  <v-card class="pa-4 white--text" color="#3C71D5" outlined>
    <v-row>
      <v-col rows="12" lg="4">
        <p class="mb-0 caption">{{ $_t('E-referral no.') }}</p>
        <p class="mb-4 body-1">{{ referral.referralKey }}</p>
        <p class="mb-0 caption" v-if="procedure">
          {{ $_t('Procedure') }}
        </p>
        <p class="mb-4 body-1" v-if="procedure">{{ procedure.procedure_name }}</p>
        <p class="mb-0 caption" v-if="referral.icd9Codes">
          {{ $_t('ICD9 codes') }}
        </p>
        <p class="mb-4 body-1" v-if="referral.icd9Codes">{{ icd9Codes }}</p>
        <p class="mb-0 caption" v-if="referral.icd10Codes">
          {{ $_t('ICD10 codes') }}
        </p>
        <p class="mb-0 body-1" v-if="referral.icd10Codes">{{ icd10Codes }}</p>
      </v-col>
      <v-col rows="12" lg="4">
        <p class="mb-0 caption" v-if="referral.referrer">
          {{ $_t('Issued by') }}
        </p>
        <p class="mb-4 body-1" v-if="referral.referrer">{{ referrer }}</p>
        <p class="mb-0 caption">{{ $_t('Issued on') }}</p>
        <p class="mb-0 body-1">{{ referralIssuedOn }}</p>
      </v-col>
      <v-col rows="12" lg="4">
        <p class="mb-0 caption">{{ $_t('Patient name and surname') }}</p>
        <p class="mb-4 body-1">{{ patient }}</p>
        <p class="mb-0 caption">{{ $_t('PESEL') }} | {{ $_t('Birth day') }}</p>
        <p class="mb-4 body-1">{{ referral.patient.patientNumber }}</p>
        <p class="mb-0 caption">{{ $_t('Home address') }}</p>
        <p class="mb-0 body-1">{{ patientAddress1 }}</p>
        <p class="mb-0 body-1">{{ patientAddress2 }}</p>
      </v-col>
    </v-row>
  </v-card>
</template>

<style scoped></style>

<script>
import { computed } from '@vue/composition-api';

export default {
  components: {},
  name: 'ReferralPreview',
  props: ['_referral', '_procedure'],
  setup(props) {
    const icd9Codes = computed(() =>
      props._referral.icd9Codes.map((icd) => icd.code).join(', ')
    );
    const icd10Codes = computed(() =>
      props._referral.icd10Codes.map((icd) => icd.code).join(', ')
    );
    const referralIssuedOn = computed(() => {
      const referralIssueDate = props._referral.referralIssueDate;
      if (referralIssueDate) {
        const y = Number(referralIssueDate.substring(0, 4));
        const m = Number(referralIssueDate.substring(4, 6)) - 1;
        const d = Number(referralIssueDate.substring(6, 8));
        const date = new Date(y, m, d);
        return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
      } else {
        return '';
      }
    });
    const referrer = computed(
      () =>
        `${props._referral.referrer.title} ${props._referral.referrer.firstName} ${props._referral.referrer.lastName}`
    );
    const patient = computed(
      () =>
        `${props._referral.patient.firstName} ${props._referral.patient.lastName}`
    );
    const patientAddress1 = computed(
      () =>
        `${props._referral.patient.address.streetName} ${props._referral.patient.address.houseNumber}`
    );
    const patientAddress2 = computed(
      () =>
        `${props._referral.patient.address.postalCode} ${props._referral.patient.address.city}`
    );

    return {
      referral: props._referral,
      procedure: props._procedure,
      icd9Codes,
      icd10Codes,
      referralIssuedOn,
      patient,
      patientAddress1,
      patientAddress2,
      referrer
    };
  }
};
</script>
