<template>
  <a v-if="showButton" class="d-inline-flex align-center" @click="goBack">
    <svg
      class="d-inline-flex"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="15" cy="15" r="15" :fill="config.themeLightPrimary" />
      <path
        d="M7.64645 14.6464C7.45118 14.8417 7.45118 15.1583 7.64645 15.3536L10.8284 18.5355C11.0237 18.7308 11.3403 18.7308 11.5355 18.5355C11.7308 18.3403 11.7308 18.0237 11.5355 17.8284L8.70711 15L11.5355 12.1716C11.7308 11.9763 11.7308 11.6597 11.5355 11.4645C11.3403 11.2692 11.0237 11.2692 10.8284 11.4645L7.64645 14.6464ZM23 14.5L8 14.5L8 15.5L23 15.5L23 14.5Z"
        fill="white"
      />
      <path
        d="M7.64645 14.6464C7.45118 14.8417 7.45118 15.1583 7.64645 15.3536L10.8284 18.5355C11.0237 18.7308 11.3403 18.7308 11.5355 18.5355C11.7308 18.3403 11.7308 18.0237 11.5355 17.8284L8.70711 15L11.5355 12.1716C11.7308 11.9763 11.7308 11.6597 11.5355 11.4645C11.3403 11.2692 11.0237 11.2692 10.8284 11.4645L7.64645 14.6464ZM23 14.5L8 14.5L8 15.5L23 15.5L23 14.5Z"
        fill="white"
      />
    </svg>
    <span v-if="showLabel" class="d-inline-flex ml-4">{{ $_t('BACK') }}</span>
  </a>
</template>

<script>
import ROUTE_NAMES from '@/store/consts/route-names';
import { computed } from '@vue/composition-api';
import config from '@/config'
import { bookingStore } from '@/store/booking-store';

export default {
  name: 'BackButton',
  props: ['_showLabel'],
  setup(props, context) {
    const showButton = computed(
      () =>
        !context.root.$route.path.startsWith(ROUTE_NAMES.eReferralsStart.path)
    );

    const goBack = () => {
      if (bookingStore.state.step.name === 'insurer') {
        context.root.$router.push(ROUTE_NAMES.procedures.path).catch(() => {})
      } else if (bookingStore.state.step.name === 'clinic') {
        context.root.$router.push(ROUTE_NAMES.insurers.path).catch(() => {})
      }
    }

    return {
      goBack,
      showButton,
      showLabel: props._showLabel,
      config
    };
  }
};
</script>
